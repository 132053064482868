.guided-prompt-list {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
}
  
.guided-prompt-item {
    margin: 5px 0;
    padding: 10px;
    background-color: #f2f2f2;
    border: none;
    cursor: pointer;
    border-radius: 4px;
}
  
.guided-prompt-item:hover {
    background-color: #e0e0e0;
}
  
.personalized-prompt {
    margin: 0 0 0 2rem;
    padding: 12px;
    background-color: var(--cl-forest);
    color: white;
    border: none;
    cursor: pointer;
    border-radius: 4px;
}
  
.personalized-prompt:hover {
    background-color: var(--cl-forest);
}

.prompt-button-container {
    margin: 2rem 0 0 0;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}


  