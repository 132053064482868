.write-section {
    display: grid;
    gap: 30px;
    justify-items: center;
    align-items: center;
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
    padding: 20px;
    height: 100%;
    /* justify-content: center; */
}

.write-accordion-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0;
}

.journal-textarea {
    width: 100%;
    outline: transparent solid 2px; 
    font-size: 1rem;
    line-height: 1.37;
    min-height: 500px;
}

.journal-title {
    font-size: 1rem;
    line-height: 1.37;
}

.write-content {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    width: 650px;
    max-width: 70%;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
}

.animation-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
}

@media (max-width: 900px) {
    .write-section {
        justify-content: center;
    }
}

@media (max-width: 769px) {
    .write-section {
        justify-content: center;
    }

    .write-content {
        max-width: 90%;
    }
}