.tos-title {
    font-size: 1.5rem;
}

.tos-text {
    margin: 1rem 0;
    font-size: 1rem;
}

.tos-header {
    font-size: 2rem;
}

.tos-main {
    margin: 1rem 0 2rem 0;
}