/* Modal styles */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}
  
.modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    width: 650px;
    max-width: 90%;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
}
  
.modal-content h3 {
    margin-bottom: 20px;
    color: var(--cl-forest);
}
  
.modal-content label {
    font-size: 1rem;
    display: block;
    margin-bottom: 15px;
}
  
.modal-content input, .modal-content select, .modal-content textarea {
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
    margin-top: 5px;
    /* min-height: 100px; */
}

.modal-content select {
    font-size: 1rem;
    cursor: pointer;
}

.modal-content select::selection {
    background-color: bisque;
}

.goal-tracker-input  {
    margin: 0;
    width: 100%;
    outline: transparent solid 2px; 
    outline-offset: 2px;
    padding-inline: 0px;
    font-size: 1rem;
    line-height: 1.37;
}

.extended-textarea {
    min-height: 100px;
    width: 100%;
    outline: transparent solid 2px; 
    outline-offset: 2px;
    padding-inline: 0px;
    font-size: 1rem;
    line-height: 1.37;
}
  
.modal-content button {
    background-color: var(--cl-sage);
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
}
  
.modal-content button:hover {
    background-color: var(--cl-forest);
}

.cancel-icon {
    width: 25px;
    height: 25px;
    fill: var(--cl-sage);
    cursor: pointer;
}

.cancel-icon:hover {
    fill: var(--cl-forest);
}

.goal-complete {
    background-color: var(--cl-forest) !important;
    margin: 0 0 0 2rem;
}
  