/* Entries Page Layout */
.entries-page {
    display: flex;
    height: 100svh;
    justify-content: center;
}

/* Sidebar for Entry List */
.sidebar {
    width: 30%;
    max-width: 350px;
    /* background-color: #f4f4f4; */
    padding: 20px;
    /* border-right: 1px solid #ddd; */
    display: flex;
    flex-direction: column;
}

/* Search Input */
.sidebar input {
    padding: 10px;
    margin: 0 !important;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.search-container {
    position: relative;
    width: 100%;
    max-width: 400px;
}

.search-icon {
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
    transition: opacity 0.2s ease;
    width: 20px;
    height: 20px;
    fill: #858585;
}

/* Entry List */
.entry-list {
    flex-grow: 1;
    overflow-y: auto;
}

/* Entry Item */
.entry-item {
    padding: 10px;
    margin: 20px 0 10px 0;
    cursor: pointer;
    border-radius: 4px;
    background-color: white;
    transition: background-color 0.3s;
}

.entry-item:hover {
    background-color: var(--cl-light);
}

.entry-item.active {
    background-color: var(--cl-sage);
}


.entry-title {
    color: var(--cl-blue);
    font-weight: 600;
}

.entry-question {
    margin: 2rem 0 1rem 0;
    padding-top: 1rem;
    font-size: 1rem;
    color: var(--cl-forest);
    font-weight: 700;
    border-top: 1px solid rgba(0, 0, 0, 0.144);
}

/* Entry Details Section */
.entry-details {
    flex-grow: 1;
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    width: 650px;
    max-width: 650px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    margin: 20px;
    overflow: auto;
}

.entry-title-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 0 10px 0;
}

.entry-tab-selector {
    width: 125px;
    display: flex;
    justify-content: space-between;
}

.entry-tab-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    fill: var(--cl-sage);
    color: var(--cl-sage);
    cursor: pointer;
}

.entry-tab-container:hover {
    fill: var(--cl-forest) !important;
    color: var(--cl-forest)
}

.entry-tab-container-active {
    fill: var(--cl-forest);
    color: var(--cl-forest)
}

.entry-tab-title {
    font-size: 1rem;
    font-weight: 500;
}

.entry-tab-item {
    width: 25px;
    height: 25px;
    margin: 0.25rem;
    fill: inherit;
    cursor: pointer;
}

.entry-tab-item-active {
    fill: var(--cl-forest);
    cursor: pointer;
}

.entry-tab-item:hover {
    fill: var(--cl-forest);
    cursor: pointer;
}

.entry-details h2 {
    font-size: 2rem;
    color: var(--cl-forest);
    margin-bottom: 10px;
}

.entry-body {
    margin-top: 20px;
    font-size: 1rem;
    line-height: 1.6;
}

/* Tab Selector */
.tab-selector {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
}

.tab-item {
    font-size: 1.5rem;
    font-weight: 500;
    color: var(--cl-sage);
    margin: 1rem;
    cursor: pointer;
}

.tab-item-active {
    font-size: 1.5rem;
    font-weight: 500;
    color: var(--cl-forest);
    margin: 1rem;
    cursor: pointer;
    border-bottom: 1px solid var(--cl-forest);
}

.tab-item:hover {
    color: var(--cl-forest);
    cursor: pointer;
}

.entry-error-msg {
    margin: 1rem 0 0 0;
}

.reflection-details {
    margin: 2rem 0 0 0;
}

.reflection-question-answer {
    box-sizing: border-box;
}

.question {
    border-top: 1px solid rgba(0, 0, 0, 0.144);
    font-size: 1rem;
    color: var(--cl-forest);
    font-weight: 700;
    padding-top: 1rem;
    margin: 1rem 0 1rem 0;
}

.answer {
    margin-top: 20px;
    font-size: 1rem;
    line-height: 1.6;
    margin: 0 0 2rem 0;
}

.ai-insights {
    margin: 2rem 0 0 0;
    border-top: 1px solid rgba(0, 0, 0, 0.144);
}

.insights-container {
    background-color: var(--cl-sage);
    padding: 20px;
    border-radius: 5px;
    box-sizing: border-box;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    margin: 20px;
}

.insight-title {
    margin: 0 0 1rem 0;
    font-size: 1rem;
    color: var(--cl-forest);
    font-weight: 700;
}

.insight-text {
    font-weight: 500;
}

.entry-icon-container {
    display: flex;
    justify-content: space-between;
}

/* FULLSCREEN CODE */
.entry-details.fullscreen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 1;
    background-color: white; /* Customize the background */
    padding: 40px 20px 120px 20px;
    overflow: auto;
    margin: 0 !important;
    max-width: 100%;
}

.entry-container.fullscreen {
    max-height: 500px;
}


@media (max-width: 1165px) {
    .sidebar {
        width: auto;
        min-width: 300px;
    }
    .entries-page {
        flex-direction: column;
    }
    .tab-selector {
        justify-content: flex-start;
    }
    .entry-icon-container {
        width: 100%;
    }
    .entry-body {
        overflow-y: auto;
    }
    /* .ai-insights {
        overflow-y: auto !important;
        height: 600px;
    } */
}

