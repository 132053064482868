.my-goals-container {
    display: grid;
    gap: 30px;
    justify-items: center;
    align-items: center;
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
    padding: 20px;
    height: 100%;
}
  
  .accordion-section {
    margin-bottom: 20px;
    border: 1px solid #ddd;
    /* border-radius: 10px; */
    /* background-color: #f4f4f4; */
  }
  
  .accordion-header {
    display: flex;
    justify-content: space-between;
    padding: 20px;
    background-color: #f4f4f4;
    cursor: pointer;
    border: none !important;
  }
  
  .accordion-content {
    padding: 10px;
    max-height: 200px; /* Adjust max height for scrollable list */
    overflow-y: auto;
  }
  
  .scrollable-list {
    max-height: 300px;
    overflow-y: auto;
    cursor: pointer;
  }
  
  ul {
    list-style-type: none;
    padding: 0;
  }
  
  ul li {
    display: flex;
    justify-content: space-between;
    padding: 8px 0;
  }

.my-goal-item {
    padding: 10px;
    cursor: pointer;
    background-color: white;
    transition: background-color 0.3s;
}

.my-goal-item:hover {
    background-color: var(--cl-light);
}

.my-goal-item.active {
    background-color: var(--cl-sage);
}

.my-goals-accordion-header {
    text-align: center;
    margin: 0 !important;
}

.goal-page-title {
    font-size: 2rem;
    color: #333;
    /* margin: 0 !important; */
}
  