/* Global Reset */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Karla", sans-serif;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0)
}

.width-collapsed {
  width: 75px;
}

.width-open {
  width: 200px;
}

.display-none {
  display: none;
}

/* General layout */
body {
  background-color: #f9f9f9;
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
}

h1, h3, p {
  margin-bottom: 15px;
}

button {
  background-color: var(--cl-sage);
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 8px;
  cursor: pointer;
}

button:hover {
  background-color: var(--cl-forest);
}

.header-dashboard {
  height: 100vh;
  /* background-color: var(--cl-light); */
  border-right: 1px solid rgb(194, 194, 194);
}

.web-nav {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 10px 15px 10px 15px;
  height: 100%;
}

/* .logo-container .expand-container{
  width: fit-content;
  right: 0px;
  bottom: -10%;
  position: absolute;
  background-color: var(--cl-off-white);
} */

.expand-container {
  width: fit-content;
  right: 0px;
  bottom: -10%;
  position: absolute;
  background-color: var(--cl-off-white);
}

.expand-container:hover {
  width: fit-content;
  right: 0px;
  bottom: -10%;
  position: absolute;
  cursor: pointer;
}

.expand-icon {
  width: 25px;
  height: 25px;
  margin: 0.25rem;
  fill: var(--cl-sage);
  cursor: pointer;
}

.collapse-icon {
  width: 25px;
  height: 25px;
  margin: 0.25rem;
  fill: var(--cl-sage);
  cursor: pointer;
  transform: rotate(180deg);
}

.logo {
  height: 30px;
  margin: 0 0 0 0.25rem;
}

.logo-container {
  display: flex;
  align-items: center;
  margin: 0 0 1.5rem 0;
  position: relative;
  cursor: pointer;
}

.logo-text {
  margin: 0 0 0 0.5rem;
  font-size: 1.75rem;
  color: var(--cl-forest);
}

.icon {
  width: 25px;
  height: 25px;
  margin: 0.25rem;
  fill: var(--cl-forest);
  cursor: pointer;
}

.web-nav-icon-container {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;
}

.icon-container {
  border-radius: 25px;
  padding: 0.25rem 0.25rem;
  display: flex;
  align-items: center;
  margin: 0 0 0.5rem 0;
  cursor: pointer;
}

.icon-container:hover {
  background-color: var(--cl-sage);
}

.icon-title {
  font-size: 1rem;
  margin: 0 0 0 1rem;
  font-weight: 600;
}

.give-container {
  border-radius: 25px;
  padding: 0.25rem 0.25rem;
  display: flex;
  align-items: center;
  cursor: pointer;
  width: 100%;
  height: 50px;
}

/* .give-container:hover {
  background-color: #dfdfdf;
  background-color: var(--cl-sage);
} */

/* Web View Layout */
.web-view {
  flex-grow: 1; 
  width: 100%;
  max-width: 100%;
  height: 100vh;
  background-color: var(--cl-white);
  overflow: auto;
}

/* Dashboard */
.dashboard {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto auto;
  grid-template-areas:
    "daytracker daytracker"
    "reflection goaltracker"
    "calmleaf calmleaf";
  gap: 30px;
  justify-items: start;
  align-items: start;
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  padding: 20px;
}

.date-section {
  grid-area: daytracker; 
  width: 100%;
}

.reflection-section {
  grid-area: reflection;
}

.dashboard-section {
  grid-area: goaltracker;
}

.calmleaf-section {
  grid-area: calmleaf;
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.dashboard-section {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.dashboard-section h3 {
  font-size: 18px;
  margin-bottom: 10px;
}

input, textarea {
  resize: vertical;
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
}

/* Button for mobile and web */
button {
  display: inline-block;
  width: 100%;
  max-width: 200px;
  text-align: center;
}

.coming-soon {
  color: var(--cl-forest);
  font-weight: 500;
}
