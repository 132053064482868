.document-header-divider {
    border-top-width: 4px;
    width: 50px;
    height: 2px;
    margin: 2rem 0;
}

.legal-section {
    /* height: 100%; */
    margin: 0 auto;
    max-width: 1200px;
    padding: 20px;
    width: 100%;
}
.legal-view {
    background-color: var(--cl-white);
    /* height: 100vh; */
    max-width: 100%;
    overflow: auto;
    width: 100%;
}

.legal-list {
    list-style-type: none;
}



@media (max-width: 798px) {
    .legal-view {
        overflow-y: auto !important;
    }
    .legal-section {
        overflow-y: auto;
        padding: 20px 20px 100px !important;
    }
}