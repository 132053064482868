.goal-accordion {
  width: 100%;
  background-color: var(--cl-white);
  border-radius: 15px;
  margin: 0 0 20px 0;
  /* border-bottom: 1px solid rgba(0, 0, 0, 0.144); */
}

.goal-accordion-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
}

.goal-item-container {
  /* border-top: 1px solid black; */
  padding: 0 20px;
  cursor: pointer;
}

.goal-item-container:hover {
  background-color: var(--cl-light);
  border-radius: 0 0 15px 15px;
}

.goal-title {
  font-weight: 500;
}

.goal-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid rgba(0, 0, 0, 0.144);
  padding: 10px 0;
}

.goal-completed {
  padding: 10px;
  font-size: 1rem;
  font-weight: 600;
  color: var(--cl-black);
  margin: 0 !important;
}

.goal-count-container {
  /* padding: 10px; */
  display: flex;
  align-items: center;
  width: 50px;
}

.open-icon {
  width: 20px;
  height: 20px;
  margin: 0 0 0 0.5rem;
  fill: var(--cl-sage);
  cursor: pointer;
  /* transform: rotate(-90deg); */
}

.close-icon {
  width: 20px;
  height: 20px;
  margin: 0 0 0 0.5rem;
  fill: var(--cl-sage);
  cursor: pointer;
  transform: rotate(90deg);
}

.check-icon {
  width: 20px;
  height: 20px;
  margin: 0 0 0 0.5rem;
  fill: var(--cl-sage);
  cursor: pointer;
}

.check-icon:hover {
  fill: var(--cl-forest);
}

.goal-map-container {
  width: 100%;
  /* padding: 20px; */
  height: fit-content;
  /* border-top: 1px solid black; */
}