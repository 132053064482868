/* Style for suggestion bubbles */
.suggestions {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin: 10px 0 20px 0;
}
  
.suggestion-bubble {
  background-color: #f1f1f1;
  border: none;
  border-radius: 20px;
  padding: 8px 15px;
  cursor: pointer;
  color: #333;
  font-size: 14px;
  transition: background-color 0.3s;
}
  
.suggestion-bubble:hover {
  background-color: var(--cl-light);
  color: white;
}
  
/* Input and button styles */
.chatbot input {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 8px;
  border: 1px solid #ccc;
}

.chatbot-title-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 0 10px 0;
}

/* Time Frame Selector Styles */
.time-frame-selector {
  display: inline-block;
  position: relative;
}

.time-frame-selector select {
  appearance: none; /* Remove default select styling */
  content: '';
  background-color: var(--cl-sage);
  border: 1px solid #ddd;;
  border-radius: 8px;
  padding: 8px 40px 8px 12px;
  font-size: 14px;
  font-family: inherit;
  font-weight: 600;
  color: var(--cl-blue);
  cursor: pointer;
  position: relative;
}

.time-frame-selector select:focus {
  outline: none;
  border-color: #999;
}

.time-frame-selector::after {
  content: "▼"; /* Down arrow */
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 12px;
  color: var(--cl-blue);
  pointer-events: none;
}

/* Style for the options inside the dropdown */
.time-frame-selector select option {
  padding: 10px;
  background: white;
  color: #333;
  font-weight: 400;
  font-size: 14px;
}

/* Highlight the selected option with the custom color */
.time-frame-selector select option:checked,
.time-frame-selector select option[selected] {
  font-weight: 700;
  color: var(--cl-blue); /* Use custom color for the selected option */
}

/* Hover state for options */
.time-frame-selector select option:hover {
  background-color: var(--cl-forest) !important; /* Custom hover background color */
  color: white;
}

/* Optional: To style the dropdown options in open state */
.time-frame-selector select {
  height: 34px;
  line-height: 34px;
  padding: 0 1rem;
}

.chat-log-container {
  max-height: 300px;  /* Set the maximum height of the chat log */
  overflow-y: auto;  /* Enable vertical scrolling */
  margin-bottom: 20px;
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 5px;
  background-color: #f9f9f9;
}

.chat-log-entry {
  margin-bottom: 15px;
}

.chat-log-entry p {
  margin: 10px 0;
}

.chat-log-entry strong {
  font-size: 1rem;
  color: var(--cl-sage);
}

.chatbot input[type="text"] {
  width: 100%;
  padding: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.suggestion-bubble {
  display: inline-block;
  background-color: #efefef;
  border-radius: 20px;
  padding: 10px 15px;
  margin: 5px;
  cursor: pointer;
}

.suggestion-bubble:hover {
  background-color: #ddd;
}

.chat-log-username {
  color: var(--cl-sage) !important;
  font-weight: 600;
}

.chat-log-calmleaf {
  color: var(--cl-blue) !important;
  font-weight: 700;
}

.chat-log-entry p {
  margin: 5px 0;
  line-height: 1.5; /* Increase line spacing for better readability */
}

.chat-log-entry ul {
  margin-left: 20px;
  padding-left: 20px;
  list-style-type: disc; /* Use bullet points for lists */
}

.chat-log-entry li {
  margin-bottom: 10px; /* Add spacing between list items */
}

.chat-log-entry strong {
  color: var(--cl-blue);
}

.chat-input {
  font-size: 16px !important;
}

.option-container {
  display: flex;
}

.max-icon {
  width: 30px;
  height: 30px;
  margin: 0 0 0 0.5rem;
  fill: var(--cl-sage);
}

/* FULLSCREEN CODE */
/* .chatbot.fullscreen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1;
  background-color: white;
  padding: 40px 20px 120px 20px;
  overflow: auto;
}

.chat-log-container.fullscreen {
  max-height: 500px;
} */

.calmleaf-section {
  flex-grow: 1;
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  max-width: 1200px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  overflow: auto;
}

.calmleaf-section.fullscreen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1;
  background-color: white; /* Customize the background */
  padding: 40px 20px 120px 20px;
  overflow: auto;
  margin: 0 !important;
  max-width: 100%;
}

.chat-log-container.fullscreen {
  width: 100%; /* Ensure it adapts to mobile with enough space for the input */
}

.icon {
  cursor: pointer;
  width: 24px;
  height: 24px;
}

.max-icon {
  fill: #6A6A6A; /* Customize color */
}

.min-icon {
  fill: #6A6A6A; /* Customize color */
}





  