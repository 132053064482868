:root{
  --cl-off-white: #f9f9f9;
  --cl-white: #ebebeb;
  --cl-light: #D5D5D5;
  --cl-sage: #B6C1B9;
  --cl-forest: #354640;
  --cl-blue: #20282E;
  --cl-black: #191919;
}

/* Ensure the header and web-view are side by side */
#root, html {
  width: 100%;
  height: 100%;
  display: flex; /* This ensures header and web-view are laid out horizontally */
  flex-direction: row; /* Header and web-view are aligned side by side */
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
