.reflection-section {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    height: fit-content;
}
.reflection-art-container {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.reflection-art {
    width: 150px;
    height: auto;
}

.completed-state {
    display: flex;
}

.completed-state span {
    font-size: 1.5rem;
    margin: 0 0 0 .5rem;
    font-weight: 500;
}

.completed-icon {
    width: 30px;
    height: 30px;
    margin: 0 0 0 0.5rem;
    fill: var(--cl-sage);
}