/* Mobile View Layout */

/* .nav-selected {
    background-color: var(--cl-sage);
}

.mobile-nav-selected {
    color: var(--cl-forest) !important;
    font-weight: 600;
}

.mobile-view footer {
    z-index: 2;
    display: flex;
    justify-content: space-around;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: white;
    padding: 10px;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
}
  
.mobile-view footer button {
    background-color: transparent;
    color: var(--cl-sage);
    font-size: 14px;
    border: none;
    cursor: pointer;
}
  
.mobile-view footer button:hover {
    color: var(--cl-sage);
} */

/* Mobile Navigation Bar */
.mobile-nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #f5f5f5; /* Background color for nav */
    padding: 15px 10px;
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 100;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
}

.nav-icon-btn {
    background: none;
    border: none;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
}

.nav-icon-btn:hover {
    background-color: #f5f5f5 !important; 
}

.nav-icon-btn .icon {
    width: 24px;
    height: 24px;
    fill: #b0b0b0;
}

.mobile-nav-selected .icon {
    fill: var(--cl-forest); /* Active icon color */
}

/* Middle Circular Button */
.entry-button-container {
    position: relative;
    top: -25px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    width: 60px;
    height: 60px;
    border-radius: 50%;
}

.entry-button {
    background-color: var(--cl-forest);
    border: none;
    width: 65px;
    height: 65px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s;
}

.entry-button:hover {
    background-color: #49a6d3; /* Hover color */
}

.entry-icon {
    width: 25px;
    height: 25px;
    fill: #fff;
}

.plant-disabled-icon {
    fill: rgb(219, 219, 219)  !important;
}

