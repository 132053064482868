@media (max-width: 798px) {
    .web-view {
        overflow-y: auto !important;
    }
    .dashboard {
        display: flex;
        flex-direction: column;
        gap: 30px;
        justify-items: start;
        align-items: start;
        width: 100%;
        margin: 0 auto;
        padding: 40px 20px 120px 20px;
        overflow-y: auto;
    }
    .date-section {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
    .day-tracker {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        flex-direction: column;
    }
    .day-circles {
        margin: 0.5rem 0;
    }
    .circle {
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 2px solid #ccc;
        border-radius: 50%;
        font-size: 14px;
        color: #666;
        position: relative;
    }
    .streak-info {
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin: 0.5rem 0 0 0 !important;
    }
    .streak-info span {
        margin: 0 0.5rem 0 0 !important;
    }
    .calmleaf-section {
        width: 100% !important;
        box-sizing: border-box;
    }
    /* .chatbot {
        width: 100% !important;
        box-sizing: border-box;
    } */
    .chatbot-title-container h3 {
        margin: 0 !important
    }
    .suggestions {
        flex-direction: row;
        flex-wrap: nowrap;
        overflow-x: auto;
    }
    .suggestion-bubble {
        min-width: fit-content !important
    }
    .entries-page {
        padding: 0 0 90px 0;
    }
    .entry-title-container {
        flex-direction: column-reverse;
        align-items: flex-start;
    }
    .entry-tab-selector {
        margin: 0 0 1rem 0;
    }
    .entry-details h2 {
        font-size: 1.5rem;
        margin-bottom: none;
    }
    .insights-container {
        margin: 20px 0;
    }
    .sidebar {
        max-width: 100%;
        max-height: 400px;
        overflow-y: auto;
    }
    .entry-details {
        width: auto !important;
        box-sizing: border-box;
    }

    .modal-overlay {
        padding: 0 20px;
    }

    .modal-content {
        max-width: 100%;
        width: 100%;
    }

    .my-goals-container {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        padding: 40px 20px 20px 20px;
    }
    .write-accordion-container {
        flex-direction: column;
        align-items: flex-start;
    }

    .write-section {
        display: flex;
        justify-content: center;
        align-items: center;
        overflow-y: auto;
        padding: 20px 20px 100px 20px !important;
    }
    .write-content {
        max-width: 100%;
    }
    .goal-btn-container {
        display: flex;
        flex-direction: column;
    }
    .goal-complete {
        margin: 1rem 0 0 0;
    }
    .prompt-button-container {
        flex-direction: column;
        align-items: flex-start;
    }
    .personalized-prompt {
        margin: 1rem 0 0 0;
    }
    .reflection-section {
        width: 100%;
    }
}