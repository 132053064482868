.day-tracker {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
  
.date-info h3 {
    font-size: 2rem;
    color: #333;
    margin: 0 !important;
}
  
.day-circles {
    display: flex;
    gap: 10px;
}

.day-text {
    font-size: 1rem;
    color: var(--cl-forest);
}

.day-text-filled {
    font-size: 1rem;
    color: var(--cl-sage);
}

.day-check-icon {
    position: absolute;
    width: 20px;
    height: 20px;
    margin: 0 0 0 0.5rem;
    fill: var(--cl-sage);
    right: 0;
    bottom: 0;
}
  
.circle {
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid #ccc;
    border-radius: 50%;
    font-size: 14px;
    color: #666;
    position: relative;
}

  /* Apply the --cl-forest color when an entry is made on that day */
.circle.filled {
    background-color: var(--cl-forest);
    border-color: var(--cl-forest);
    color: white; /* Change text color to stand out against the green */
}
  
.circle.today {
    border-color: var(--cl-forest);
    color: var(--cl-forest);
}
  
.streak-info {
    font-size: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0;
}
  
.streak-info span {
    margin: 0;
    color: var(--cl-forest);
    font-weight: 700;
}
  