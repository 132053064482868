/* Centering the login container */
.login-page {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100svh;
    background-color: #f9f9f9;
}

/* Login container styling */
.login-container {
    background-color: white;
    padding: 40px;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    width: 300px;
    text-align: center;
}

/* Headline */
.login-container h1 {
    margin-bottom: 20px;
    font-size: 24px;
    color: #333;
}

/* Error message styling */
.error-message {
    color: red;
    margin: 1rem 0;
}

/* Form field styling */
.login-container form {
    display: flex;
    flex-direction: column;
}

.login-container label {
    font-size: 14px;
    margin-bottom: 8px;
    color: #666;
}

.login-container input {
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ddd;
    border-radius: 4px;
}

/* Login/Signup button */
.login-button {
    color: white;
    border: none;
    padding: 10px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    margin: 20px 0 20px 0;
    width: 100% !important;
    max-width: none !important;
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
}

/* Google button */
.google-button {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white !important;
    border: 1px solid #ccc !important;
    font-size: 1rem !important;
    color: #333 !important;
    width: 100% !important;
    max-width: none !important;
    margin: 0 0 1rem 0;
}
.google-icon {
    width: 25px;
    margin: 0 1rem 0 0;
}

/* Toggle between sign-in and sign-up */
.toggle-auth p {
    font-size: 14px;
    color: #666;
}

.toggle-auth span {
    color: var(--cl-blue);
    cursor: pointer;
    font-weight: bold;
}
.toggle-auth span:hover {
    color: var(--cl-sage);
    cursor: pointer;
    font-weight: bold;
}

/* Container for password input and icon */
.password-input-container {
    position: relative;
    display: flex;
    align-items: center;
}
  
  /* Password input field */
.password-input-container input {
    width: 100%;
    padding-right: 40px; /* Add padding to make space for the icon */
    margin: 0 !important;
}

.login-input {
    font-size: 18px !important;
}

/* Password toggle icon */
.password-toggle-icon {
    position: absolute;
    right: 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
    height: 100%;
}
  
  /* Add a hover effect to the eye icon */
.password-toggle-icon svg {
    width: 20px;
    height: 20px;
    color: #888;
}
  
.password-toggle-icon:hover svg {
    color: #555;
}

/* Success message after account creation */
.success-message {
    color: green;
    margin: 1rem 0;
    font-size: 14px;
}

.login-link {
    margin: 0 1rem 0 0;
}

  
